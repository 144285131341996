<template>
  <div class="testimonials-page">
    <div class="info_page">
      <header>
        <h1>Testimonials</h1>
      </header>
      <div class="info_section" id="testimonial_video">
        <div class="info_section_content" @click="onVideoClick">
          <lazy-youtube-video src="https://www.youtube.com/embed/tpsEc28jEKs" />
        </div>
      </div>
      <div class="info_section" id="testimonials">
        <div class="info_section_content">
          <person-quotes-list :data="testimonialsListState"></person-quotes-list>
        </div>
      </div>
    </div>
    <prospect-request-end-actions></prospect-request-end-actions>
  </div>
</template>

<script>
import PersonQuotesList from '@common/elements/layouts/personQuotesList';
import AnalyticsHandler from '@common/utils/analytics/analytics.handler';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import LazyYoutubeVideo from 'vue-lazy-youtube-video';
import {mapGetters} from 'vuex';

import ProspectRequestEndActions from '@/components/common/prospectRequestEndActions';

export default {
  metaInfo: {
    title: 'Testimonials',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Read the stories and reviews of customers we’ve helped connect with their roots.',
      },
    ],
  },
  components: {LazyYoutubeVideo, PersonQuotesList, ProspectRequestEndActions},
  computed: {
    ...mapGetters(['testimonialsListState']),
  },
  methods: {
    onVideoClick() {
      AnalyticsHandler.trackPlayVideoEvent('Testimonials page');
      AnalyticsMainHandler.trackPlayVideoEvent('Testimonials page', getRoutePageName(this.$route));
    },
  },
  name: 'testimonials.page.vue',
};
</script>

<style scoped lang="scss">
@import '@common/style/info.page';

#testimonial_video {
  .info_section_content {
    padding-top: 0;
    margin-top: -1px;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
}

#testimonials {
  .info_section_content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    h1 {
      margin-bottom: 30px;
    }
  }
}
</style>

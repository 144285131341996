<template>
  <div class="about-page">
    <header class="background_header">
      <div class="readable_content">
        <h1>Our Story</h1>
      </div>
    </header>

    <div class="readable_content">
      <p class="intro">
        Our story begins with a young boy in the Dutch countryside, perched on his grandfather’s knee in awe.
      </p>

      <div class="image-caption story">
        <img
          :data-src="$getAsset('/assets/about/IMG_3158-cropped-e1581395262927.jpg')"
          class="lazyload with-shadow"
          alt="A boy with grandfather"
        />
      </div>

      <p>
        Imagine: A family fortune built on sugar plantations in Indonesia, squandered on racing horses… hidden opium in
        the pillars of your home…! Such were the juicy tales of his family’s rise and fall, far across the sea.
      </p>

      <p>
        The boy grew up to wonder, “If our memories are all rooted in Indonesia, why do I have a Chinese face? Who were
        my Chinese ancestors, and what motivated them to leave their homeland? With my hodgepodge heritage, what does it
        mean to be Chinese?”
      </p>

      <p>
        Driven by his curiosity – and embarrassed that he couldn’t order his own bowl of noodles in Chinatown – he flew
        to China in search of answers. Retracing his ancestors’ footsteps back to Fujian, he found himself standing in
        wonder before the same altar where his great-great-great-great-grandfather had prayed 200 years before. After
        seven generations, their family’s journey had finally come full circle.
      </p>

      <div class="image-caption full">
        <img
          :data-src="$getAsset('/assets/about/Tianbao-Fujian-web.jpg')"
          class="lazyload with-shadow"
          alt="People standing in front of a temple"
        />
      </div>

      <p>
        Along this quest to “close the gaps” for himself, the boy discovered countless more who also felt disconnected
        from their roots. In fact, over 150 million Overseas Chinese navigate multicultural crossroads every day. In
        2012, Huihan Lie founded My China Roots to help the Chinese diaspora trace their roots and feel more connected
        to the stories of their ancestors.
      </p>

      <div class="image-caption story">
        <img
          :data-src="$getAsset('/assets/about/First-name-card-web.jpg')"
          class="lazyload with-shadow"
          alt="Business card"
        />
        <span>From solopreneur to global team, we’ve come a long way!</span>
      </div>

      <p>
        Our team has since grown to 14 full-time sleuths across 5 countries. We are proud to be the global leader in
        ancestry research and travel in China, empowering you to trace your roots from anywhere in the world. Especially
        grandfather’s knee!
      </p>

      <div class="bold">Learn More</div>
      <ul class="related_links">
        <li><router-link :to="{name: 'our-team'}">Meet the Team</router-link></li>
        <li><router-link :to="{name: 'careers'}">Careers</router-link></li>
        <li><router-link :to="{name: 'press'}">Press & Media</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'About Us',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'From the Netherlands, to China via Indonesia: our story begins with a young boy in the Dutch countryside, perched on his grandfather’s knee in awe.',
      },
    ],
  },
  name: 'about.page.vue',
  components: {},
};
</script>

<style scoped lang="scss">
@import '@common/style/mixins.scss';
.about-page {
  header {
    background-image: get-asset('/assets/about/HH-Ancestor-Wedding-Party-web-bw.jpg');
    @include overlayGradient(0deg, rgba(10, 5, 0, 0.76) 20%, rgba(0, 0, 0, 0) 60%);
  }

  .bold {
    margin-top: 32px;
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    .image-caption.story {
      float: right;
      max-width: 400px;
      margin: 0 0 0 24px;
    }
  }
}
</style>

<template>
  <div class="careers-page">
    <div class="readable_content">
      <h1>Careers</h1>
      <h5>We connect families between worlds.</h5>
      <p>
        Based in 5 countries around the globe,&nbsp;we are a fast-growing, multicultural family of scrappy sleuths and
        cyber-savvy designers. Curious, empathetic, and resourceful, we are passionate about building bridges across the
        Chinese diaspora. One clue at a time,&nbsp;we transform family mystery into living history.
      </p>
      <div class="image-container">
        <img
          :data-src="$getAsset('/assets/about/careers/welcome3.jpg')"
          class="lazyload with-shadow"
          alt="Team members"
        />
      </div>
      <p><strong>Ready to uncover more stories connecting us? Apply now to join our global family!</strong></p>
      <p>
        <strong
          >We have no open positions available at this time. If you are interested in future opportunities, please send
          your CV to <a href="mailto:recruitment@mychinaroots.com">recruitment@mychinaroots.com</a>. Thanks for your
          interest!</strong
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Careers',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Are you passionate about history & cultural heritage preservation, big data & fast-growing startups, and/or the Chinese diaspora? Join our My China Roots family today!',
      },
    ],
  },
  name: 'careers.page.vue',
};
</script>

<style scoped lang="scss">
.careers-page {
  .image-container {
    display: flex;
    justify-content: center;

    img {
      max-height: 400px;
      object-position: top;
    }
  }

  .say-hello {
    opacity: 0.7;

    a {
      color: $text-color;
      text-decoration: underline;

      &:hover {
        color: black;
      }
    }
  }
}
</style>
